.screen.time-to-load {
    background: #fff;
    &.time-for-medication {
        &.late-dispense {
            .inner {
                box-shadow: inset 0 0 56px 2px #FF3C33;
            }
        }
        .inner {
            box-shadow: inset 0 0 56px 2px #42C237;
            .name {
                p {
                    height: 108px;
                }
            }
            p.desc {
                margin-top: 0;
            }
            a.big-btn {
                justify-content: center;
                margin: 17px auto 0;
                width: 400px;
                box-shadow: 1px 4px 4px rgb(0 0 0 / 40%);
                height: 151px;
                span {
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 48px;
                }
            }
        }
    }
    &.auxiliary {
        .inner {
            box-shadow: inset 0 0 56px 2px #42C237;
            width: 100%;
            .name {
                margin: 34px auto 0;
                p {
                    padding: 0;
                    height: 88px;
                    display: flex;
                    justify-content: center;
                    border-top: 1px solid #259FDA;
                    border-bottom: 1px solid #259FDA;
                }
            }
            p.desc {
                max-width: 369px;
                margin: -10px auto 0;
                font-weight: 700;
                font-size: 42px;
                line-height: 132%;
                height: 168px;
                border-bottom: 1px solid #259FDA;
            }
            p.question {
                font-weight: 500;
                font-size: 28px;
                letter-spacing: 0.4px;
                text-align: center;
                margin: -10px auto 0;
                width: 400px;
                justify-content: center;
            }
            .button-block {
                width: 400px;
                box-shadow: 1px 4px 4px rgb(0 0 0 / 40%);
                height: 159px;
                border-radius: 20px;
                border: 1px solid $main-blue-dark;
                display: flex;
                margin: -10px auto 0;
                box-sizing: border-box;
                a {
                    width: 50%;
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 40px;
                    background: linear-gradient(176.43deg, rgba(47, 146, 200, 0) 49.7%, #2F92C8 231.37%);
                    line-height: 48px;
                    letter-spacing: 0.1em;
                    &:first-child {
                        border-right: 2px solid $blue;
                        border-bottom-left-radius: 20px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 20px;
                    }
                }
            }
        }
    }
    .inner {
        padding: 0;
        margin: 0;
        box-shadow: inset 0 0 56px 2px #F3DB04;
        height: 100%;
        box-sizing: border-box;
        .name {
            margin: 41px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                height: 128px;
                width: 369px;
                border-top: 1px solid $blue;
                border-bottom: 1px solid $blue;
                font-family: 'Libre Baskerville', sans-serif;
                font-weight: 400;
                font-size: 36px;
                line-height: 45px;
                box-sizing: border-box;
                padding: 0 50px;
                color: #002E6F;
            }
        }
        p.desc {
            margin-top: 23px;
            height: 168px;
            font-weight: 700;
            font-size: 56px;
            line-height: 103%;
        }
        .btns-row {
            bottom: 72px;
            a {
                padding: 0 20px;
            }
        }
    }
}
