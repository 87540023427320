.screen.success {
    p.success {
        margin-top: 225px;
        font-family: 'Libre Baskerville';
        font-style: normal;
        font-weight: 700;
        font-size: 59px;
        line-height: 120%;
        display: flex;
        height: 96px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.4px;
        color: #002E6F;
    }
    p.title {
        font-weight: 500;
        font-size: 48px;
        margin-top: 0;
    }
    &.medication {
        p.success {
            margin-top: 171px;
        }
        p.title {
            margin-top: 10px;
            height: 168px;
        }
    }
}
