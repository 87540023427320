.screen.enter-password {
    p.title {
        font-weight: 400;
        font-size: 40px;
        line-height: 120%;
        margin-top: 35px;
        height: auto;
    }
    p.second {
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        height: auto;
    }
    form {
        margin-top: 20px;
        input {
            width: 337px;
            height: 60px;
            font-family: "Inter", sans-serif;
            font-weight: 400;
            font-size: 22.7817px;
            line-height: 28px;
            text-align: center;
            color: $main-blue-dark;
            background: #fff;
            border: 1.26565px solid $main-blue-dark;
            border-radius: 7.59391px;
        }
        .group-buttons {
            width: 337px;
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            margin-top: 17px;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                font-family: "Inter", sans-serif;
                font-weight: 400;
                margin-bottom: 9px;
                font-size: 22.7817px;
                line-height: 28px;
                color: $main-blue-dark;
                height: 54px;
                cursor: pointer;
                box-sizing: border-box;
                background: linear-gradient(180deg, #FFFFFF 0%, #F0FBFF 72.26%, #D6F3FF 100%);
                border: 1.26565px solid $main-blue-dark;
                box-shadow: 0 2.5313px 5.06261px rgba(0, 0, 0, 0.1);
                border-radius: 7.59391px;
                &.wide {
                    min-width: 79px;
                    &.transparent {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                &.space {
                    min-width: 209px;
                }
            }
        }
    }
    .btns-row {
        bottom: 30px;
    }
}
