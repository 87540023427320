.screen.settings {
    .bottom-arr-block {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 78px;
        min-width: 100%;
        border-top: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            width: 100%;
            justify-content: center;
            text-align: center;
            height: 100%;
            img {
                transform: rotate(180deg);
            }
        }
    }
    p.title {
        margin-top: 60px;
        font-family: 'Libre Baskerville', sans-serif;
        height: 33px;
        font-weight: 700;
        font-size: 42px;
        line-height: 24px;
        letter-spacing: 0.18px;
    }
    .settings-items {
        box-sizing: border-box;
        padding: 0 46px 0 39px;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        margin-top: 63px;
        a.access-admin-btn {
            margin-bottom: 21px;
            width: 185px;
            height: 187px;
            background: $main-blue-dark;
            box-shadow: 1px 4px 4px rgba(0,0,0,.4);
            border-radius: 20px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background: linear-gradient(186.11deg, #E6F2F8 63.94%, rgba(47, 146, 200, 0) 241.9%);
                top: -1%;
                left: 0;
                width: 100%;
                height: 101%;
                z-index: 1;
                border-radius: 20px;
            }
            .img-block {
                position: absolute;
                z-index: 2;
                bottom: 94px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
            span.text {
                font-weight: 700;
                font-size: 29px;
                line-height: 29px;
                letter-spacing: 0.18px;
                color: #002E6F;
                height: 39px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 27px;
                box-sizing: border-box;
                left: 0;
                padding: 0 30px;
                z-index: 2;
                width: 100%;
            }
        }
    }

}
