.screen.choose-network {
    p.title {
        margin-top: 71px;
        height: 33px;
        font-family: 'Libre Baskerville', sans-serif;
        font-weight: 700;
        font-size: 38px;
        line-height: 24px;
        letter-spacing: 0.18px;
        text-transform: capitalize;
        color: #002E6F;
    }
    p.local {
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 0.4px;
        margin-top: 45px;
    }
    ul {
        border: 1px solid $blue;
        border-radius: 8px;
        background: #FFFFFF;
        li {
            border-bottom: 1px solid $blue;
            &:first-child {
                a {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }
            }
            &:last-child {
                border-bottom: none;
                a {
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
            }
            a {
                height: 56px;
                width: 377px;
                background: $white;
                box-sizing: border-box;
                padding: 0 17px;
                &.active {
                    background-color: $blue;
                    svg path {
                        fill: #fff;
                    }
                    span {
                        color: $white;
                    }
                }
                span {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    display: flex;
                    margin-left: 25px;
                    align-items: center;
                    color: #002E6F;
                }
            }
        }
    }
    a.bottom-arr {
        border-top: 1px solid #fff;
        width: 100%;
        height: max-content;
        margin-top: 30px;
        padding-top: 42px;
        display: flex;
        justify-content: center;
        img {
            transform: rotate(180deg);
        }
    }
    .btns-row {
        bottom: 49px;
    }
}
