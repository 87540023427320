.portal-screen.login {

    .first-row {
        display: flex;
        background-color: #fff;
        .left {
            width: 525px;
            height: 702px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            img,svg {
                position: relative;
                z-index: 3;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgb(0, 45, 116, .55);
                z-index: 1;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgb(49, 152, 205, .15);
                z-index: 2;
            }
        }
        .right {
            padding-top: 39px;
            margin: 0 auto;
            width: 430px;
            .inner {
                height: 100%;
                position: relative;
                p.title {
                    font-family: "Libre Baskerville", sans-serif;
                    font-weight: 700;
                    color: #0C2E6F;
                    font-size: 36px;
                    line-height: 22px;
                }
                p.secondary {
                    margin-top: 18px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #1B3765;
                }
                form {
                    margin-top: 16px;
                    padding-top: 21px;
                    border-top: 1px solid #F5F5F5;
                    .input--group {
                        margin-bottom: 17px;
                    }
                    button {
                        width: 100%;
                        height: 66px;
                    }
                }
                a.forgot-pass {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    margin-top: 16px;
                    justify-content: center;
                    color: #001A72;
                }
                p.secured {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #8692A6;
                    display: flex;
                    position: absolute;
                    bottom: 18px;
                    left: 50%;
                    transform: translateX(-50%);
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    img, svg {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &.account-set-up {
        .first-row {
            .right {
                .inner {
                    form {
                        margin-top: 12px;
                        .inputs-row {
                            display: flex;
                            justify-content: space-between;
                            .input--group {
                                width: 204px;
                            }
                        }
                        .input--group {
                            position: relative;
                            input {
                                height: 69px;
                            }
                            span.show-confirm,
                            span.show-pass {
                                position: absolute;
                                bottom: 16px;
                                right: 28px;
                                cursor: pointer;
                                & svg {
                                    opacity: 0.5
                                }
                            }
                            span.opacity {
                                & svg {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
