.progress {
    position: relative;
    width: 120px;
    height: 26px;
    line-height: 26px;
    font-family: arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #259FDA;
    border-radius: 2px;
    overflow: hidden;
  }
  .progress .progress-text {
    position: absolute;
    top: -2px;
    width: 120px;
    height: 26px;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.1px;
    font-family: "Inter", sans-serif;
    color: #259FDA;
    padding-left: 5px;
    font-variant: small-caps;
  }
  .progress .progress-bar {
    height: 100%;
    overflow: hidden;
    background-color: #259FDA;
    overflow: hidden;
    position: relative;
    -webkit-animation: animate 2s;
    animation: animate 2s;
  }
  .progress .progress-bar .progress-text {
    color: #fff;
  }
  @-webkit-keyframes animate {
    0% {
      width: 0%;
    }
  }
  @keyframes animate {
    0% {
      width: 0%;
    }
  }
  

