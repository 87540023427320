.screen.go-back {
    background: #fff;
    .inner {
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 56px 2px #FFDF38;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            margin-top: 64px;
        }
        p.title {
            margin: 46px 0 31px;
            font-weight: 400;
            font-size: 40px;
        }
        a.big-btn {
            width: 367px;
            height: 133px;
            justify-content: center;
            box-shadow: 1px 4px 4px rgb(0 0 0 / 40%);
            &.white-bg {
                background: #fff;
                margin-bottom: 16px;
                border: 2px solid #013076;
                border-radius: 20px;
                box-shadow: none;
                span {
                    color: #013076;
                }
                &:before {
                    background: #fff;
                }
            }
            span {
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #fff;
            }
        }
    }
}
