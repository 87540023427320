@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'scss/style.scss';

.timetable-wrapper {
    // height: 100% !important;
}

.weekly-schedule-wrapper {
    margin-top: 35px;
    position: relative;
}
