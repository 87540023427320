.screen.access-admin {
    .top-arr-block {
        height: 101px;
        min-width: 100%;
        border-bottom: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            height: 100%;
            width: 100%;
            justify-content: center;
        }
    }
    a.access-admin-btn {
        width: 399px;
        height: 187px;
        margin-top: 70px;
        background: $main-blue-dark;
        box-shadow: 1px 4px 4px rgba(0,0,0,.4);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            background: linear-gradient(186.11deg, #E6F2F8 63.94%, rgba(47, 146, 200, 0) 241.9%);
            top: -1%;
            left: 0;
            width: 100%;
            height: 101%;
            z-index: 1;
            border-radius: 20px;
        }
        span {
            margin-top: 24px;
            font-weight: 700;
            font-size: 29px;
            line-height: 24px;
            letter-spacing: 0.18px;
            color: #002E6F;
        }
        span, img {
            z-index: 2;
            position: relative;
        }
    }
}
