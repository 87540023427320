.searchInputs {
  display: flex;
  position: relative;
}

.search input {
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #fff;
  border-radius: 25px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 334px;
  padding: 0 14px 0 38px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #9A9A9A;
  transition: all .2s;
}

.activeSearch input {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 2px solid #002E6F !important;
}

.searchIcon {
  height: 20px;
  width: 20px;
  background-color: white;
  position: absolute;
  left: 19px;
  top: 13px
}

.searchResults {
  width: 330px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  left: 2px;
  z-index: 1;
}

.searchResults::-webkit-scrollbar {
  display: none;
}

.searchResults .dataItem {
  width: 100%;
  height: 33px;
  display: flex;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: lightgrey;
  } 
}

.dataItem p {
  margin-left: 10px;
  font-size: 14px;
}

.clearSearch {
  width: 18px;
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}