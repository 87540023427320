.screen.pin-number {
    p.title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        height: 34px;
        margin-top: 112px;
    }
    .input-group {
        margin-top: 10px;
        padding: 0 53px;
        display: flex;
        justify-content: space-between;
        input {
            font-family: "Inter", sans-serif;
            width: 76px;
            height: 68px;
            background: #FFFFFF;
            border: 1px solid $main-blue-dark;
            border-radius: 6px;
            box-sizing: border-box;
            text-align: center;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            color: $main-blue-dark;
        }
    }
    .input-buttons {
        margin-top: 58px;
        display: flex;
        padding: 0 60px;
        flex-flow: wrap;
        justify-content: space-between;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            cursor: pointer;
            box-sizing: border-box;
            height: 100px;
            margin-bottom: 26px;
            border-radius: 6px;
            border: 1px solid $main-blue-dark;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            color: $main-blue-dark;
            background: linear-gradient(180deg, #FFFFFF 0%, #F0FBFF 72.26%, #D6F3FF 100%);
            &.save {
                background: #459FDA;
                color: #fff;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}
