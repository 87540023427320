.screen.load-device {
    ul {
        display: flex;
        margin: 98px 0 49px 0;
        li {
            margin: 0 17px;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 73px;
                height: 76px;
                border: 2px solid #D2D8DD;
                border-radius: 50%;
                font-weight: 700;
                font-size: 20px;
                line-height: 16px;
                box-sizing: border-box;
                &.current {
                    background-color: $blue;
                    border: 2px solid $blue;
                    color: $white;
                }
            }
        }
    }
    img.device {
        border: 1px solid #002E6F;
    }
    p.title {
        margin-top: 62px;
        font-weight: 700;
    }
    .btns-row {
        bottom: 70px;
    }
}
