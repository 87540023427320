table {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
    border-radius: 8px;
    margin-top: 20px;

    &.caregiver {

        thead,
        tbody {
            tr {
                td {

                    &:first-child,
                    &:nth-child(2) {
                        width: 180px;
                    }

                    &:nth-child(3) {
                        width: 220px;
                    }
                }
            }
        }
    }

    thead {
        tr td {
            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #002E6F;
                cursor: pointer;
                position: relative;

                &.orderable:not(.activeSort):before {
                    content: '';
                    position: absolute;
                    top: 60%;
                    transform: translateY(-50%);
                    right: -15px;
                    width: 10px;
                    height: 6px;
                    opacity: 0.5;
                    background-image: url('../../assets/img/portal/table-arr.svg');
                    background-size: cover;
                    background-position: center center;
                }

                &.orderable.activeSort:before {
                    content: '';
                    position: absolute;
                    top: 60%;
                    transform: translateY(-50%);
                    right: -15px;
                    width: 10px;
                    height: 6px;
                    opacity: 0.5;
                    background-image: url('../../assets/img/portal/table-arr.svg');
                    background-size: cover;
                    background-position: center center;
                }

                &.orderable.activeSort.desc:before {
                    transform: translateY(-50%) rotate(180deg);
                }

                &.orderable:not(.activeSort):hover:before {
                    opacity: 1;
                }
            }

            &:first-child {
                padding-left: 15px;
            }

            &.last-column {
                text-align: right;
                padding-right: 15px;

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    text-decoration-line: underline;
                    color: #259FDA;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            border-top: 1px solid #DBDDE0;

            td {
                a.table-email {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    text-decoration-line: underline;
                    color: #259FDA;
                }

                .bar-col {
                    display: flex;
                    align-items: center;
                    border-radius: 2px;
                    border: 1px solid #259FDA;
                    background: transparent;
                    width: 120px;
                    padding-left: 5px;
                    height: 26px;
                    position: relative;

                    p.bar {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        color: #FFFFFF;
                        position: relative;
                        z-index: 2;
                    }

                    span.bg {
                        position: absolute;
                        display: inline-block;
                        top: 0;
                        left: 0;
                        height: 100%;
                        background-color: #259FDA;
                        z-index: 1;
                    }
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #52575C;
                }

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 15px;
                    width: 100px;
                }

                &.last-col {
                    .btns {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        height: 100%;

                        a {
                            cursor: pointer;
                        }

                        a.edit-table-row-btn {
                            margin-right: 27px;
                            position: relative;
                            top: -2px;
                        }

                        a.portal-button {
                            height: 30px;
                            margin-right: 17px;
                            width: 133px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 7px;
                            text-transform: capitalize;
                            color: #FFFFFF;
                            justify-content: center;
                        }

                        form {
                            button {
                                width: 18px;
                                padding: 0;
                                background-color: transparent;
                                border: none;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    tr {
        height: 56px;
    }
}

.email-address {
    & span {
        color: #259FDA;
        text-decoration: underline;
    }
}

.pagination {
    margin-top: 32px;

    ul {
        display: flex;
        justify-content: center;

        li {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            a {
                cursor: pointer;
                width: 32px;
                height: 32px;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.1px;
                border: 2px solid #E8E8E8;
                border-radius: 50%;
                justify-content: center;
                transition: .3s all ease;

                &.disabled {
                    opacity: .3;
                }

                &:hover,
                &.current {
                    background-color: $blue;
                    border: 2px solid $blue;
                    color: #fff;
                }
            }

            &:first-child a,
            &:last-child a {
                border: none;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    p.show {
        display: block;
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;

        span {
            font-weight: bold;
        }
    }
}
