.screen.dispense-early {
    position: relative;
    &.dark:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.58);
        z-index: 99;
    }
    .inner {
        width: 100%;
        height: 100%;
        background: $main-blue-light;
        .data-top-content {
            margin-top: 51px;
            position: relative;
            z-index: 2;
            p {
                color: #fff;
            }
        }
        .name {
            margin: 46px auto 0;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                height: 108px;
                width: 369px;
                border-top: 1px solid $white;
                border-bottom: 1px solid $white;
                font-family: 'Libre Baskerville', sans-serif;
                font-weight: 400;
                font-size: 36px;
                line-height: 45px;
                box-sizing: border-box;
                padding: 0 50px;
                color: #fff;
            }
        }
        p.reminder {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            z-index: 2;
            font-weight: 400;
            height: 33px;
            margin-top: 39px;
            font-size: 28px;
            line-height: 34px;
            color: #FFFFFF;
        }
        a.big-btn {
            width: 400px;
            height: 151px;
            background: $main-blue-dark;
            box-sizing: border-box;
            padding: 0 90px;
            box-shadow: 1px 4px 4px rgb(0 0 0 / 40%);
            margin: 80px auto 0;
            span {
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                color: #002E6F;
            }
            &:before {
                background: linear-gradient(180deg, #E6F2F8 63.94%, rgba(47, 146, 200, 0) 241.9%);
            }
        }
        position: relative;
        img.bg {
            position: absolute;
            bottom: -40px;
            right: 0;
            z-index: 1;
        }
    }
}
