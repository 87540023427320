a, p {
    font-family: 'Inter', sans-serif;
    text-align: center;
    letter-spacing: 0.4px;
    font-size: 40px;
    line-height: 120%;
    color: $blue;
    margin: 0;
    padding: 0;
    text-decoration: none;
    // height: 88px;
    display: flex;
    align-items: center;
    &.title {
        max-width: 400px;
    }
}
// main screen container 480x800
.screen {
    width: 480px;
    height: 800px;
    margin: 0 auto 30px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(183.1deg, #F5F5F7 2.57%, #D2D8DD 89.5%);
}
//main screen container for portal
.portal-screen {
    display: block;
    background-color: #F5F5F7;
    table tr td,h1,a {
        font-family: 'Inter', sans-serif;
        height: auto;
        font-size: 18px;
    }
    .content {
        padding: 60px 82px 0;
        max-width: 1152px;
        margin: 0 auto;

        .inner {
            overflow: hidden;
            padding: 22px 30px;
            background: #FFFFFF;
            border: 1px solid #F5F5F7;
            box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
            border-radius: 12px;
        }
    }
}
// container with two buttons at bottom
.btns-row {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 76px;
    left: 50%;
    transform: translateX(-50%);
}
// two buttons at bottom with white and blue bg
a.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 194px;
    height: 93px;
    box-sizing: border-box;
    border-radius: 20px;
    text-decoration: none;
    margin: 0 9px;
    span {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        line-height: 30px;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
    }
    &.transparent {
        border: 2px solid #013076;
        color: #013076;
    }
    &.blue {
        background-color: $main-blue-dark;
        position: relative;
        color: $white;
        box-shadow: 1px 4px 4px rgba(0,0,0,.4);
        &:before {
            content: '';
            position: absolute;
            background: linear-gradient(180deg, #2F92C8 0%, rgba(47, 146, 200, 0) 100%);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 20px;
        }
    }
}
//big button single
a.big-btn {
    background-color: $main-blue-dark;
    position: relative;
    border-radius: 20px;
    color: $white;
    width: 309px;
    height: 111px;
    z-index: 2;
    span {
        position: relative;
        z-index: 2;
        font-weight: 700;
        font-size: 40px;
        height: 53px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }
    &:before {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, #2F92C8 0%, rgba(47, 146, 200, 0) 100%);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 20px;
    }
}
//close button at top right angle
span.close-btn {
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
}
//header screen
header {
    background: linear-gradient(0deg, $main-blue-light 2.57%, $main-blue-dark 120.54%);
    min-height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px 0 15px;
    box-sizing: border-box;
    a {
        height: auto;
        &.home-btn {
            margin-right: 16px;
        }
    }
    .left {
        display: flex;
        align-items: center;
        .logo-block {
            display: flex;
            align-items: center;
            span.line {
                display: inline-block;
                width: 1px;
                height: 39px;
                background-color: #fff;
                margin-right: 13px;
            }
            img.first {
                margin-right: 6px;
            }
        }
    }
}
//top back button
a.back-btn {
    display: flex;
    position: absolute;
    top: 110px;
    left: 16px;
    height: 46px;
    span {
        &.arr {
            display: flex;
            margin: 0 10px 0 0;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            border: 1px solid $blue;
            border-radius: 50%;
            box-sizing: border-box;
        }
        &.text {
            font-weight: 400;
            font-size: 28px;
            line-height: 24px;
            letter-spacing: 0.18px;
        }
    }
}
//top data row
.data-top-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;
    p.time {
        font-weight: 700;
        font-size: 78px;
        line-height: 78px;
        display: flex;
        align-items: flex-end;
        span {
            font-size: 47px;
            font-weight: 400;
            line-height: 57px;
            margin-left: 20px;
        }
    }
    p.day {
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        height: 33px;
        margin-top: 10px;
    }
}
//input group for portal forms
.input--group {
    display: flex;
    flex-direction: column;
    label {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #696F79;
        margin-bottom: 9px;
        span.required {
            color: red;
        }
    }
    input {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #002E6F;
        height: 65px;
        padding: 0 25px;
        background: #F5F5F7;
        border: 2px solid #F5F5F7;
        border-radius: 7px;
        &:focus {
            outline: none;
            border: 2px solid $main-blue-light;
        }
    }
}
//button at portal screens
.portal-button {
    background: $main-blue-light;
    font-family: 'Inter', sans-serif;
    border-radius: 31px;
    border: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    height: 40px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;

    &:hover {
        background-color: $main-blue-dark;
    }
}

.portal-white-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background-color: $white;
    color: $main-blue-dark;
    height: 42px;
    justify-content: center;
    border: 1px solid $main-blue-dark;
    border-radius: 31px;
    cursor: pointer;

    &:hover {
        background-color: $main-blue-dark;
        color: $white;
    }
}

.portal-cancel {
    color: #002E6F;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;
}

// notification block
.notification-block {
    margin-bottom: 36px;
    .notifications {
        margin-top: 44px;
        display: flex;
        align-items: center;
        label {
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: #002E6F;
            margin-right: 55px;
        }
        .notif-block {
            position: relative;
            label.status {
                position: absolute;
                cursor: pointer;
                z-index: 2;
                left: -12px;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 400;
                font-size: 18px;
                line-height: 18px;
                color: #002E6F;
                transition: .3s all ease;
                &.move {
                    padding-left: 20px;
                }
            }
            input {
                position: relative;
                display: none;
                cursor: pointer;
                & + .checkbox {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 80px;
                        height: 32px;
                        background: #E8E8E8;
                        border-radius: 22px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        border-radius: 50%;
                        width: 32px;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        height: 32px;
                        background: $main-blue;
                        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
                        transition: .3s all ease;
                        opacity: .3;
                    }
                }
                &:checked + .checkbox {
                    &:after {
                        content: '';
                        right: -45px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    p.desc-notif {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #002E6F;
        margin: 30px 0 24px 0;
    }
}
