@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-Thin.woff"),
    url("../../../assets/fonts/Inter-Thin.woff") format('woff');
    font-weight: 100;
}
@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-Regular.woff"),
    url("../../../assets/fonts/Inter-Regular.woff") format('woff');
    font-weight: 400;
}
@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-Medium.woff"),
    url("../../../assets/fonts/Inter-Medium.woff") format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-SemiBold.woff"),
    url("../../../assets/fonts/Inter-SemiBold.woff") format('woff');
    font-weight: 600;
}
@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-Bold.woff"),
    url("../../../assets/fonts/Inter-Bold.woff") format('woff');
    font-weight: 700;
}
@font-face {
    font-family: 'Inter';
    src: url("../../../assets/fonts/Inter-ExtraBold.woff"),
    url("../../../assets/fonts/Inter-ExtraBold.woff") format('woff');
    font-weight: 800;
}
