footer.portal {
    padding: 52px 0 50px 72px;
    background-color: $main-blue-dark;
    .row {
        display: flex;
        justify-content: space-between;
        &.rights {
            margin-top: 36px;
        }
        p.title {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.15px;
            color: #FFFFFF;
        }
        .left {
            p.desc {
                font-style: italic;
                font-weight: 300;
                font-size: 10px;
                line-height: 115.69%;
                letter-spacing: 0;
                text-align: center;
                color: #FFFFFF;
                padding-left: 14px;
            }
        }
        .right {
            display: flex;
            .col {
                &:first-child {
                    margin-right: 30px;
                    width: 215px;
                }
                &:last-child {
                    width: 250px;
                }
                ul.menu-footer {
                    margin-top: 13px;
                    li {
                        margin-bottom: 7px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 32px;
                            color: #D9DBE1;
                        }
                    }
                }
            }
        }
        .rights-block {
            padding-left: 14px;
            p.right {
                font-weight: 500;
                font-size: 12px;
                height: 28px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: #FFFFFF;
            }
        }
        .follow-us {
            width: 250px;
            ul.socials {
                margin-top: 18px;
                display: flex;
                align-items: center;
                li {
                    margin-right: 18px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

}
