.portal-top-header {
    height: 90px;
    background: #259FDA;
    border-radius: 0 0 10px 10px;
    display: flex;
    padding: 0 26px;
    // justify-content: space-between;
    align-items: center;

    .right {
        display: flex;
        align-items: center;

        form.search {
            position: relative;
            margin-right: 20px;

            input {
                height: 40px;
                background: #FFFFFF;
                border: 2px solid #fff;
                border-radius: 25px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 14px;
                width: 334px;
                padding: 0 14px 0 38px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: #9A9A9A;

                &:focus {
                    outline: none;
                    border: 2px solid #002E6F;
                }
            }

            button {
                width: 20px;
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: none;
                cursor: pointer;
                position: absolute;
                left: 19px;
                top: 13px;
            }
        }

        a.auth-user {
            display: flex;
            margin-right: 17px;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #FFFFFF;

            svg {
                margin-right: 12px;
            }
        }

        form.log-out {
            button {
                height: 33px;
                display: flex;
                align-items: center;
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                padding: 0 11px;
                letter-spacing: 0.15px;
                color: $main-blue;
                background: #FFFFFF;
                border: 1px solid #259FDA;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}
