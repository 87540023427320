.screen.get-started,
.screen.device-updating,
.screen.welcome {
    background: $main-blue-light;
    position: relative;
    img {
        position: relative;
        z-index: 2;
        &.bg {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 44px;
        }
    }
}
.screen.welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    p.title {
        font-family: 'Libre Baskerville', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.4px;
        color: #FFFFFF;
        margin-bottom: -10px;
        position: relative;
        z-index: 2;
    }
}
.screen.device-updating {
    img.logo {
        margin-top: 121px;
    }
    p.title {
        font-weight: 600;
        font-size: 40px;
        line-height: 53px;
        color: #FFFFFF;
        position: relative;
        z-index: 2;
        margin-top: 88px;
        padding: 0 100px;
    }
    .loading-progress {
        margin-top: 55px;
        img {
            opacity: .4;
            &.loaded {
                opacity: 1;
            }
        }
    }
}
.screen.get-started {
    img.logo {
        margin-top: 121px;
    }
    p.secondary {
        position: relative;
        z-index: 2;
        font-weight: 500;
        font-size: 30px;
        line-height: 120%;
        color: #FFFFFF;
        height: 80px;
        margin-top: 22px;
    }
    p.title {
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: 40px;
        line-height: 53px;
        color: #FFFFFF;
        padding: 0 80px;
        height: 160px;
    }
    a.big-btn {
        display: flex;
        height: 170px;
        width: 347px;
        margin-top: 29px;
        justify-content: center;
        flex-direction: column;
        box-shadow: 1px 4px 4px rgba(0,0,0,.4);
        span {
            line-height: 48px;
            height: 80px;
            margin-top: 20px;
        }
    }
    a.call-support {
        font-weight: 700;
        font-size: 24px;
        text-decoration-line: underline;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        height: 50px;
        color: #FFFFFF;
        margin-top: 36px;
    }
}
