* {
    box-sizing: border-box;
}
html,
body {
    scroll-behavior: smooth;
    margin: 0;
}
h1, h2 {
    margin: 0;
}
table{
    border-collapse: collapse;
    border-spacing: 0;
}
ol, ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
span {
    font-family: "Inter", sans-serif;
}
.container {
    display: flex;
    flex-flow: wrap;
}
.links-nav {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 999;
    background-color: blue;
    border-radius: 20px;
    padding: 10px 20px;
    a {
        font-size: 16px;
        color: #fff;
        height: 20px;
    }
}
