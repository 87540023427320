.screen.load-error-screen {
    background: $white;
    box-shadow: inset 0 0 56px 2px #FF3C33;
    .error-sign {
        text-align: center;
        padding-top: 127px;
    }
    p.title {
        color: #0B508F;
        font-weight: 700;
        margin-top: 32px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p.secondary {
        font-weight: 600;
        width: 400px;
        margin: 48px auto;
        height: 88px;
        display: flex;
        align-items: center;
    }

}
