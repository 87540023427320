// Loading field animation
@-webkit-keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}

@keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}

.loading-field {
  height: 30px;
  vertical-align: middle;
  padding: 8px;
  span {
    display: block;
    height: 6px;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
}