.screen.confirm {
    p.desc {
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 160px;
        font-weight: 700;
        font-size: 40px;
        letter-spacing: 0.4px;
        padding: 0 80px;
    }
    .btns-row {
        bottom: 56px;
        a.btn {
            height: 113px;
            padding: 0 30px;
        }
    }
}
