.screen.contact-support {
    p.title-basker {
        font-family: 'Libre Baskerville', sans-serif;
        height: 33px;
        font-weight: 700;
        font-size: 39px;
        line-height: 24px;
        letter-spacing: 0.18px;
        margin-top: 112px;
    }
    p.desc {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        max-width: 400px;
        letter-spacing: 0.4px;
        margin-top: 164px;
    }
    a.phone {
        font-weight: 800;
        font-size: 40px;
        height: max-content;
        margin-top: 5px;
    }
    .row {
        width: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 88px;
        margin-top: 30px;
        &.first-row {
            margin-top: 78px;
        }
        .left {
            span {
                font-family: 'Inter', sans-serif;
                font-weight: 700;
                font-size: 28px;
                line-height: 120%;
                color: $blue;
                letter-spacing: 0.4px;
                display: block;
                &.second {
                    font-weight: 400;
                }
            }
        }
    }
    a.big-btn {
        margin-top: 71px;
        box-shadow: 1px 4px 4px rgba(0,0,0,.4);
    }
    .control-row {
        margin-top: 54px;
        &.first {
            margin-top: 62px;
        }
        p.title {
            font-weight: 700;
            font-size: 28px;
            justify-content: flex-start;
            min-width: 400px;
            align-items: flex-start;
        }
        .control-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                display: inline-block;
                width: 40px;
                height: 38px;
                border-radius: 50%;
                background-color: $blue;
                position: relative;
                cursor: pointer;
                &.minus:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 20px;
                    height: 2px;
                    background-color: #fff;
                    transform: translate(-50%, -50%);
                }
                &.plus {
                    &:after,
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 20px;
                        height: 2px;
                        background-color: #fff;
                        transform: translate(-50%, -50%);
                    }
                    &:after {
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }

            }
            ul.scale {
                width: 302px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 2px solid $blue;
                li {
                    height: 27px;
                    width: 30px;
                    margin-bottom: -1px;
                    position: relative;
                    &.fill {
                        background-color: #259FDA;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 34px;
                        width: 1px;
                        background-color: $blue;
                    }
                    &:nth-child(6) {
                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 45px;
                            width: 1px;
                            background-color: $blue;
                        }
                    }
                    &:last-child {
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            height: 34px;
                            width: 1px;
                            background-color: $blue;
                        }
                    }
                }
            }
        }
    }
}
