@mixin schedule-item {
    .schedule-item {
        cursor: pointer;
        position: relative;

        &:hover .modal-message-schedule {
            display: block;
        }
    }

    .modal-message-schedule {
        background-color: #ffffff;
        border: 1px solid #002E6F;
        border-radius: 10px;
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        width: 240px;
        padding: 10px 15px;
        display: none;
        z-index: 2;

        .row-message {
            flex-direction: column;

            p.value {
                text-align: left;
                margin-top: 5px;
            }
        }

        .row-edit {
            flex-direction: column;
            border-bottom: none;

            .item {
                display: flex;
            }
        }

        .row {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                border-bottom: 1px solid #E8E8E8;
                padding-bottom: 6px;
                margin-bottom: 6px;
            }

            p.title {
                font-weight: 400;
                margin-right: 5px;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: -0.02em;
            }

            p.value {
                font-weight: 700;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: -0.02em;
            }
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            height: 100%;
        }

        .row {
            position: relative;
            z-index: 3;
        }
    }
}

.portal-screen.resident-details {
    .content {
        padding-top: 86px;
        padding-bottom: 100px;

        .heading {
            display: flex;
            margin-bottom: 50px;

            p.title {
                font-family: "Libre Baskerville", sans-serif;
                font-weight: 700;
                font-size: 30px;
                line-height: 22px;
                margin-right: 50px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: -2px;
                    right: -26px;
                    width: 3px;
                    height: 30px;
                    background-color: $blue;
                }
            }

            p.id {
                font-size: 24px;
                line-height: 22px;
                font-weight: 400;
            }
        }

        .details-navbar {
            margin-bottom: 23px;

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    position: relative;

                    a {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -0.02em;
                        position: relative;

                        &.active {
                            font-weight: 700;
                            color: #259FDA;

                            &:before {
                                content: '';
                                position: absolute;
                                bottom: -2px;
                                width: 100%;
                                height: 2px;
                                background-color: #259FDA;
                            }
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -70px;
                        width: 1px;
                        height: 100%;
                        background-color: #259FDA;
                    }

                    &:last-child:before {
                        display: none;
                    }
                }
            }
        }

        .details-data {
            background: #FFFFFF;
            box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
            border-radius: 8px;
            padding: 0 35px 0 40px;
            overflow: hidden;
            margin-bottom: 29px;

            .data-header {
                background-color: #FAFAFA;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 65px;

                .left,
                .right {
                    display: flex;
                    align-items: center;

                    p.title {
                        font-family: "Libre Baskerville", sans-serif;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        margin-right: 25px;
                    }

                    p.online {
                        font-size: 14px;
                        line-height: 22px;
                        margin-right: 10px;
                    }
                }
            }

            .data-row {
                display: flex;
                align-items: center;
                min-height: 54px;
                position: relative;
                padding: 16px 0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 120%;
                    height: 2px;
                    background-color: #EFEFEF;
                    transform: translateX(-50%);
                }

                p.data-title {
                    font-weight: 700;
                    min-width: 235px;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    opacity: 0.75;
                }

                p.data-value {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: #52575C;
                    max-width: 250px;
                    text-align: left;
                }

                .alternative {
                    display: flex;

                    .col {
                        &:last-child {
                            margin-left: 250px;
                        }

                        p.data-value {
                            span {
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                                color: #999EA1;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        .device-items {
            background: #FFFFFF;
            box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
            border-radius: 8px;
            padding-left: 120px;
            overflow: hidden;
            padding-bottom: 10px;
            margin-bottom: 25px;

            .device-header {
                background: #FAFAFA;
                border-radius: 8px 8px 0 0;
                height: 56px;
                display: flex;
                align-items: center;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 200%;
                    height: 1px;
                    background-color: #DBDDE0;
                }

                p.title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    width: 300px;
                }
            }

            .device-row {
                display: flex;
                align-items: center;
                height: 56px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 200%;
                    height: 1px;
                    background-color: #DBDDE0;
                }

                p.value {
                    font-weight: 400;
                    font-size: 14px;
                    width: 300px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #52575C;

                    img {
                        margin-right: 10px;
                    }
                }

                form {
                    position: absolute;
                    top: 50%;
                    right: 42px;
                    transform: translateY(-50%);

                    button {
                        border: none;
                        cursor: pointer;
                        background-color: transparent;
                    }
                }
            }
        }

        .add-device {
            margin: 30px 0 50px;
            display: flex;
            cursor: pointer;

            span {
                display: flex;
                margin-left: 10px;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: #459FDA;
            }
        }

        .add-schedule-item {
            margin: 25px 0;
            display: flex;
            cursor: pointer;

            span {
                display: flex;
                margin-left: 10px;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: #459FDA;
            }
        }

        & .disabled {
            cursor: initial;

            span {
                color: #C4C4C4;
            }
        }
    }

    &.care-team {
        position: relative;

        .content {
            .device-items {
                padding-left: 0;

                .device-header {
                    padding-left: 30px;
                    background-color: #E8E8E8;

                    p.title {
                        width: 160px;
                    }
                }

                .device-row {
                    padding-left: 30px;

                    p.value {
                        width: 160px;
                    }
                }
            }
        }

    }

    &.schedule {
        position: relative;

        .content {}

        .modal-add-item-pill-pack {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 160, 223, 0.24);
            z-index: -99;
            opacity: 0;
            visibility: hidden;
            transition: .3s all ease;

            &.show {
                z-index: 99;
                opacity: 1;
                visibility: visible;

                .inner {
                    bottom: 52px;
                }
            }

            .inner {
                background: #FFFFFF;
                border-radius: 8px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 995px;
                padding: 20px 49px 42px;
                transition: .3s all ease;

                p.heading {
                    font-family: "Libre Baskerville", sans-serif;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.25px;
                    padding-left: 20px;
                }

                form {
                    margin-top: 21px;
                    position: relative;

                    .form-row {
                        border-bottom: 1px solid #C4C4C4;
                        padding-bottom: 19px;
                        padding-left: 20px;

                        &.dose {
                            padding-top: 21px;
                            padding-bottom: 27px;

                            p.row-title {
                                margin-bottom: 4px;
                            }

                            .dose-date-block {
                                border: 1px solid #667080;
                                border-radius: 6px;
                                width: 125px;
                                position: relative;

                                input.date-pill-pack {
                                    border: none;
                                    height: 48px;
                                    border-radius: 6px;
                                    width: 120px;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    letter-spacing: 3px;
                                    color: #002E6F;
                                    padding: 0 12px;

                                    &:focus {
                                        outline: none;
                                    }
                                }

                                .am-pm {
                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    transform: translateY(-50%);

                                    span {
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 22px;
                                        letter-spacing: -0.02em;
                                        color: #002E6F;
                                        cursor: pointer;

                                        &.active {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }

                        &.days {
                            margin-top: 12px;

                            p.secondary {
                                font-style: italic;
                                margin-top: 11px;
                                font-size: 14px;
                                line-height: 22px;
                                letter-spacing: -0.02em;
                            }

                            .select-days {
                                display: flex;
                                align-items: center;

                                input {
                                    width: 12px;
                                    height: 12px;
                                    cursor: pointer;
                                    position: relative;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 16px;
                                        height: 16px;
                                        border: 1.5px solid #002E6F;
                                        border-radius: 3px;
                                        background-color: #fff;
                                    }

                                    &:checked:after {
                                        content: '';
                                        border-radius: 3px;
                                        transform: translate(-50%, -50%);
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        height: 12px;
                                        width: 12px;
                                        background-image: url('../../../assets/img/portal/checkbox_mark.svg');
                                        background-position: center center;
                                        background-size: contain;
                                    }
                                }

                                label {
                                    font-weight: 400;
                                    font-size: 16px;
                                    cursor: pointer;
                                    line-height: 22px;
                                    letter-spacing: -0.02em;
                                    font-family: 'Inter', sans-serif;
                                    color: $blue;
                                }

                                &.all {
                                    margin-top: 27px;

                                    input {
                                        margin-right: 10px;
                                    }

                                    label {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .days-block {
                                display: flex;
                                margin-top: 24px;
                                margin-bottom: 24px;

                                .select-days {
                                    display: flex;
                                    flex-direction: column;
                                    margin-right: 20px;

                                    label {
                                        margin-bottom: 4px;
                                    }
                                }
                            }
                        }

                        p.row-title {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 22px;
                            letter-spacing: -0.02em;

                            span {
                                color: red;
                            }
                        }

                        .switcher {
                            height: 60px;
                            width: 290px;
                            border: 1px solid $blue;
                            border-radius: 30px;
                            margin-top: 13px;
                            display: flex;
                            justify-content: space-between;
                            position: relative;

                            .item {
                                cursor: pointer;

                                img {
                                    opacity: .3;
                                }

                                p {
                                    font-size: 12px;
                                    line-height: 15px;
                                    margin-top: 5px;
                                    text-transform: uppercase;
                                    color: #667080;
                                }

                                span {
                                    font-weight: 700;
                                    color: #667080;
                                }

                                &.pill-pack {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    width: 140px;

                                    &.active {
                                        background: rgba(37, 159, 218, 0.12);
                                        border-right: 1px solid #002E6F;
                                        border-radius: 30px;

                                        img {
                                            opacity: 1;
                                        }

                                        p {
                                            font-weight: 700;
                                            color: $blue;
                                        }
                                    }
                                }

                                &.auxiliary {
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    align-items: center;
                                    width: 160px;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    height: 100%;

                                    &.active {
                                        background: rgba(37, 159, 218, 0.12);
                                        border-left: 1px solid #002E6F;
                                        border-radius: 30px;

                                        p,
                                        span {
                                            font-weight: 700;
                                            color: $blue;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .form-buttons {
                        display: flex;
                        align-items: center;
                        margin-top: 17px;
                        padding-left: 20px;

                        a.cancel {
                            font-size: 16px;
                            line-height: 19px;
                            text-decoration-line: underline;
                        }

                        button {
                            height: 42px;
                            width: 90px;
                            margin-left: 20px;
                        }
                    }

                    .add-item-aux {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: #fafafa;
                        border-radius: 31px;
                        width: 484px;
                        padding: 12px 29px 75px 38px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: -24px;
                            width: 24px;
                            height: 100%;
                            background-color: #fff;
                        }

                        p.title {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 22px;
                            letter-spacing: -0.02em;

                            span {
                                color: red;
                            }
                        }

                        select {
                            width: 100%;
                            background: #FFFFFF;
                            border-radius: 6px;
                            height: 47px;
                            border: 2px solid #fff;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: -0.02em;
                            color: #002E6F;
                            margin-top: 8px;
                            cursor: pointer;
                            padding: 0 12px;

                            &:focus {
                                outline: none;
                                border: 2px solid $blue;
                            }
                        }

                        p.add-aux-reminder-btn {
                            margin: 13px 0 25px;
                            justify-content: flex-end;

                            a {
                                font-size: 14px;
                                line-height: 22px;
                                letter-spacing: -0.02em;
                                color: $main-blue;

                                span {
                                    font-weight: 700;
                                    margin-left: 10px;
                                    text-decoration: underline;
                                }
                            }
                        }

                        textarea {
                            margin-top: 7px;
                            width: 100%;
                            border: none;
                            height: 95px;
                            background: #FFFFFF;
                            border-radius: 6px;
                            padding: 12px 16px;
                            resize: none;
                            font-family: 'Inter', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: -0.02em;
                            color: #002E6F;
                        }

                        p.max-length {
                            justify-content: flex-end;
                            font-size: 14px;
                            margin-bottom: 20px;
                            line-height: 22px;
                            letter-spacing: -0.02em;
                            color: #C4C4C4;
                        }

                        .track-for-adherence {
                            margin-top: 20px;
                            display: flex;

                            .item {
                                margin-right: 40px;

                                input {
                                    position: relative;
                                    cursor: pointer;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 20px;
                                        height: 20px;
                                        background-color: #fff;
                                        border: 2px solid $blue;
                                        border-radius: 50%;
                                    }

                                    &:checked:before {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 12px;
                                        height: 12px;
                                        background-color: $blue;
                                        border-radius: 50%;
                                        z-index: 22;
                                    }
                                }

                                label {
                                    font-family: 'Inter', sans-serif;
                                    font-size: 16px;
                                    line-height: 22px;
                                    letter-spacing: -0.02em;
                                    color: #002E6F;
                                    margin-left: 15px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.save_1 .layer-blue {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 160, 223, 0.24);
            z-index: 9;
            transition: .3s all ease;
        }
    }
}

.input--group--error {
    label {
        color: #ff0000;
    }

    input {
        border-color: #ff0000;
    }

    .error {
        color: #ff0000;
        margin-top: 5px;
    }
}

.modal-add-device {
    .inner {
        form {
            .error {
                color: #ff0000;
                margin-top: 5px;
            }

            .input--group--error {
                & input {
                    border-color: #ff0000;
                }
            }
        }

        .row {
            p.name {
                min-width: 90px;
            }

            .input--group {
                & input {
                    width: 630px;
                }
            }
        }
    }
}

.modal-add-care-team-member,
.modal-add-device {
    width: 100%;
    height: 100%;
    padding: 43px 35px 28px;

    .inner {
        p.title {
            font-family: "Libre Baskerville", sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.25px;
            max-width: unset;
        }

        form {
            margin-top: 72px;

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #EFEFEF;
                padding-bottom: 22px;

                p.name {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    opacity: 0.75;
                    margin-right: 80px;

                    & span {
                        color: red;
                    }
                }

                select {
                    background: #F5F5F7;
                    border-radius: 6px;
                    height: 47px;
                    width: 630px;
                    padding: 0 12px;
                    font-weight: 400;
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                    color: $blue;
                    cursor: pointer;
                    border: 2px solid #F5F5F7;

                    &:focus {
                        outline: none;
                        border: 2px solid $blue;
                    }
                }
            }

            p.info {
                font-weight: 400;
                font-style: italic;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #667080;
                margin-top: 20px;

                a {
                    font-size: 16px;
                    line-height: 22px;
                    margin-left: 5px;
                    font-style: normal;
                    text-decoration: underline;
                    color: $main-blue-light;
                }
            }

            .buttons-block {
                margin-top: 70px;
                display: flex;
                align-items: center;

                a.cancel {
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: underline;
                    margin-right: 12px;
                }

                button {
                    width: 90px;
                }
            }
        }
    }
}

.no-data {
    & p {
        font-style: italic;
        font-weight: 100;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #52575C;

    }
}

.gray-header {
    overflow: hidden;

    & thead {
        background: #E8E8E8;
    }
}

.light-gray-header {
    overflow: hidden;

    & thead {
        background: #FAFAFA;
    }
}

.device-table {

    & thead,
    & tbody {
        tr {
            td {
                &:first-child {
                    padding-left: 120px;
                }
            }
        }
    }

    & td {
        width: 45%;
    }

    .device-status {
        text-transform: capitalize;
        display: flex;

        & svg {
            margin-right: 4px;
        }
    }
}

.schedule-container {
    background: #FFFFFF;
    box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
    border-radius: 8px;
    margin-bottom: 31px;

    .row-header {
        height: 56px;
        padding-left: 26px;
        background: #FAFAFA;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DBDDE0;

        p.title {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            font-family: "Libre Baskerville", sans-serif;
        }
    }

    .schedule-inner {
        padding: 18px 28px 31px 31px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 185px;

            .row {
                margin-bottom: 30px;

                p.row-title {
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.25px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: 0;
                        background-color: $blue;
                    }
                }
            }

            .pill-packs {
                margin-top: 9px;

                .row {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-bottom: 2px;

                    &.period-days {
                        border-bottom: 1px solid #E8E8E8;
                        margin-bottom: 9px;
                        padding-bottom: 6px;
                    }

                    p.title {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    span.cumber {
                        width: 25px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 22px;
                        color: $blue;
                    }

                    &.total {
                        margin-top: 8px;
                        height: 40px;
                        border-top: 1px solid #E8E8E8;
                        border-bottom: 1px solid #E8E8E8;

                        &.adherence {
                            font-weight: 700;
                            border-bottom: none;
                        }
                    }
                }
            }

            .legend-row {
                margin-top: 13px;

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 12px;

                    span {
                        font-weight: 700;
                        margin-right: 6px;
                        font-size: 12px;
                        line-height: 12px;

                        img {
                            width: 19px;
                        }
                    }
                }
            }
        }

        .right {
            width: 698px;

            .schedule-info {
                border: 1px solid #E8E8E8;
                border-radius: 10px;
                padding: 15px 16px 23px;
                position: relative;

                .read-or-hide {
                    & span {
                        display: inline-block;
                        color: #259FDA;
                        text-decoration: underline;
                        font-size: 16px;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }

                .row {
                    display: flex;
                    margin-bottom: 14px;

                    &:nth-child(3) {
                        margin-bottom: 0;
                    }

                    p {
                        align-items: flex-start;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -0.02em;

                        span {
                            max-width: 560px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: -0.02em;
                            margin-left: 5px;

                            a {
                                display: inline-block;
                                color: #259FDA;
                                text-decoration: underline;
                                font-size: 16px;
                                font-weight: 700;
                            }

                            &.desc {
                                font-weight: 400;
                                text-align: left;
                                margin-left: 16px;
                            }
                        }

                        &:nth-child(2) {
                            margin-left: 25px;
                        }
                    }
                }

                a.edit-btn {
                    position: absolute;
                    top: 15px;
                    right: 17px;
                }

                & .notes {
                    width: 100%;
                }

                &__note {
                    margin-left: 16px;
                    width: 85%;
                    height: 85px;
                    border: 1px solid #E8E8E8;
                    background: #FFFFFF;
                    border-radius: 6px;
                    padding: 8px;
                    resize: none;
                    font-family: 'Inter', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                    color: #002E6F;
                }
            }

            p.heading {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                font-family: "Libre Baskerville", sans-serif;
                margin: 25px 0 17px;
            }

            .weekly-schedule {
                border: 1px solid #DBDDE0;
                border-radius: 8px;

                .row-days {
                    display: flex;

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(1) {
                        .schedule-item {
                            &:hover .modal-message-schedule{
                                position: fixed;
                                transform: translateY(-100%);
                            }
                        }
                    }
                }

                .columns {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                }

                .newcolumn {
                    // flex-direction: column;
                    // border-bottom: none !important;

                    &:first-child {
                        background-color: #fafafa;
                        // border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 10px;
                    }
                }

                .actionbuttons {
                    position: absolute;
                    right: 0;
                    background-color: #ffffff;

                    .col {
                        width: auto;
                        // z-index: 1;
                    }
                }

                .row-days,
                .newcolumn {
                    display: flex;
                    border-bottom: 1px solid #E8E8E8;

                    // position: relative;
                    &.open {
                        z-index: 99;
                        background-color: #fff;

                        .col {
                            border-right: none;
                            background-color: #fff !important;

                            p.num {
                                font-weight: 700;
                                color: $blue;
                                position: relative;
                                z-index: 2;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    width: 110%;
                                    height: 130%;
                                    background: #F5F5F7;
                                    border-radius: 6px;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: -2;
                                }

                                &:after {
                                    content: 'Time';
                                    font-family: 'Inter', sans-serif;
                                    position: absolute;
                                    top: -20px;
                                    left: 0;
                                    font-weight: 400;
                                    font-size: 8px;
                                    line-height: 22px;
                                    letter-spacing: 0.2em;
                                    color: #002E6F;
                                }
                            }
                        }

                        a.schedule-delete,
                        a.schedule-edit {
                            display: none;
                        }

                        a.close,
                        a.portal-button {
                            display: block;
                        }
                    }

                    &:last-child {
                        border-bottom: none;

                        .col:first-child {
                            border-bottom-left-radius: 10px;
                        }
                    }

                    .col {
                        width: 80px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        border-right: 1px solid #E8E8E8;
                        position: relative;

                        & svg {
                            z-index: 2;
                        }

                        @include schedule-item;

                        &:nth-child(9) {
                            border-right: none;
                            width: 56px;
                        }

                        div.aux {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            text-transform: uppercase;
                            color: $blue;
                            font-family: "Inter", sans-serif;

                            & .red-close {
                                cursor: pointer;
                                position: absolute;
                                top: 13px;
                                right: 19px;
                            }

                            & .add-aux {
                                cursor: pointer;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 29px;
                                color: #3198CD;
                            }
                        }

                        p.day {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 18px;
                            color: #52575C;
                        }

                        p.num {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #52575C;
                        }

                        &:first-child {
                            // background-color: #fafafa;
                            border-top-left-radius: 10px;
                        }
                    }

                    a.schedule-delete {
                        right: 12px;
                    }

                    a.schedule-edit {
                        right: 40px;
                    }

                    a.portal-button {
                        right: 9px;
                        width: 65px;
                        height: 26px;
                        font-size: 14px;
                        line-height: 24px;
                        display: none;
                    }

                    a.close {
                        top: -10px;
                        right: -30px;
                        display: none;
                    }

                    .aux-open-content {
                        position: absolute;
                        top: 53px;
                        left: 0;
                        width: 100%;
                        padding-bottom: 46px;
                        background-color: #fff;
                        display: flex;
                        padding-left: 100px;

                        .col {
                            width: 205px;
                            height: max-content;
                            display: flex;
                            align-items: flex-start;
                            margin-right: 16px;

                            p.title {
                                font-weight: 400;
                                font-size: 8px;
                                line-height: 22px;
                                letter-spacing: 0.2em;
                                text-transform: uppercase;
                            }

                            input {
                                height: 28px;
                                width: 100%;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 22px;
                                letter-spacing: -0.02em;
                                background: #F5F5F7;
                                border-radius: 6px;
                                color: $blue;
                                font-family: 'Inter', sans-serif;
                                border: 1px solid #F5F5F7;
                                padding: 0 6px;

                                &:focus {
                                    outline: none;
                                    border: 1px solid $blue;
                                }
                            }

                            textarea {
                                background: #F5F5F7;
                                border-radius: 6px;
                                resize: none;
                                height: 80px;
                                width: 100%;
                                padding: 4px 10px;
                                font-weight: 400;
                                border: 1px solid #F5F5F7;
                                font-family: 'Inter', sans-serif;
                                font-size: 12px;
                                line-height: 22px;
                                letter-spacing: -0.02em;
                                color: #002E6F;

                                &:focus {
                                    outline: none;
                                    border: 1px solid $blue;
                                }
                            }

                            &:last-child {
                                margin-right: 0;
                                width: 290px;
                            }
                        }
                    }
                }
            }
        }

        &.adherence {
            .right {
                .row {
                    margin-bottom: 30px;

                    p.row-title {
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 0.25px;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            left: 0;
                            bottom: 0;
                            background-color: $blue;
                        }
                    }
                }

                .dates-range {
                    display: flex;

                    .col {
                        display: flex;
                        align-items: center;
                        margin-right: 40px;

                        label.date {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            font-family: 'Inter', sans-serif;
                            letter-spacing: -0.02em;
                            color: $blue;
                            margin-right: 15px;
                        }

                        input {
                            width: 80px;
                            height: 32px;
                            background: #F5F5F7;
                            border-radius: 6px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: 2px;
                            color: #667080;
                            border: 1px solid #F5F5F7;
                            padding: 0 12px;

                            &:focus {
                                outline: none;
                                border: 1px solid $blue;
                            }
                        }

                        img {
                            margin-left: 10px;
                        }
                    }
                }

                .weekly-schedule {
                    margin-top: 35px;
                    position: relative;

                    a.arr-bottom {
                        position: absolute;
                        top: 466px;
                        left: 0;
                        height: 52px;
                        width: 85px;
                        z-index: 99;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #fafafa;
                        border-top: 1px solid #E8E8E8;
                        border-right: 1px solid #E8E8E8;
                        border-bottom-left-radius: 8px;
                    }

                    .hours {
                        overflow-y: scroll;
                        overflow-y: overlay;
                        height: 461px;
                        position: relative;

                        &::-webkit-scrollbar {
                            background-color: transparent;
                            width: 13px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: #fafafa;
                            border: 1px solid #E8E8E8;

                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $blue;
                        }

                        .row-days:last-child {
                            margin-bottom: 52px;
                        }
                    }

                    .row-days {
                        min-height: 52px;
                        border-bottom: none;
                        position: relative;

                        &.green {
                            .col {
                                background-color: #D6F2D3;

                                &:first-child {
                                    background-color: #fafafa;
                                }
                            }
                        }

                        &.yellow {
                            .col {
                                background-color: rgba(255, 223, 56, 0.2);

                                &:first-child {
                                    background-color: #fafafa;
                                }
                            }
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #e8e8e8;
                        }

                        &.days-row {
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            background-color: #fafafa;
                            height: 57px;
                            width: 100%;

                            .col {
                                height: 56px;
                            }
                        }

                        .col {
                            height: auto;
                            min-height: 51px;
                            width: 85px;
                            // position: relative;

                            &:nth-child(2) {
                                padding-left: 10px;
                            }

                            svg.bar,
                            img.pill {
                                position: absolute;
                                transform: translate(-50%, -50%);
                            }
                        }

                        a.arr-calendar {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);

                            &.prev {
                                left: 94px;
                            }

                            &.next {
                                right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.schedule-item.event {
    position: relative !important;

    .modal-message-schedule {
        bottom: 35px !important;
    }
}

.schedule-action {
    display: flex;

    .schedule-edit {
        margin-right: 8px;
    }
}
