.modal-delete {
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 160, 223, 0.24);
  z-index: -999;
  opacity: 0;
  visibility: hidden;
  &.show {
      z-index: 999;
      opacity: 1;
      visibility: visible;
  }
  .inner {
      position: absolute;
      top: 376px;
      left: 50%;
      padding: 43px 35px 28px;
      transform: translateX(-50%);
      background-color: #fff;
      border: 2px solid rgba(102, 112, 128, 0.07);
      box-shadow: 0 4px 26px rgba(0, 46, 111, 0.06);
      border-radius: 10px;
      transition: .3s all ease;
      width: 525px;
      height: 193px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p.title {
          font-family: "Inter", sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 22px;
          letter-spacing: -1px;
      }
      p.sub-title {
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
      }
      .buttons-block {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .portal-button {
            width: 170px;
            height: 40px;
            justify-content: center;
            margin-left: 10px;
          }
          .portal-white-button {
            width: 170px;
            height: 40px;
          }
      }
  }
}
