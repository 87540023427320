.screen.dispose {
    background: #E8E8E8;
    .inner {
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 250px 48px #FF3C33;
        display: flex;
        flex-direction: column;
        align-items: center;
        p.title {
            position: relative;
            margin-top: 110px;
            display: flex;
            justify-content: center;
            span {
                position: relative;
                z-index: 2;
                font-family: 'Roboto', sans-serif;
                display: flex;
                align-items: center;
                height: 88px;
                font-weight: 700;
                font-size: 56px;
                line-height: 59px;
                letter-spacing: 0.4px;
                color: #FFFFFF;
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 242px;
                height: 242px;
                background-image: url('../../assets/img/dispose.svg');
                z-index: 1;
            }
        }
        p.second {
            margin-top: 106px;
            height: 171px;
            font-weight: 700;
            font-size: 28px;
            line-height: 106%;
            padding: 0 98px;
        }
        a.big-btn {
            width: 400px;
            justify-content: center;
            height: 151px;
            margin-top: 29px;
        }
    }
}
