.portal-screen.provider {
    &.caregiver-details {
        .content {
            margin-bottom: 94px;
            .inner {
                .inner-heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .buttons-block {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a.edit-btn {
                            margin-right: 30px;
                            position: relative;
                            top: -2px;
                        }
                        form {
                            button {
                                border: none;
                                background-color: transparent;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .form-inner {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    .form-row {
                        height: 92px;
                        .inputs {
                            .input--group {
                                p.account-data {
                                    margin-top: 15px;
                                }
                            }
                        }
                        .single {
                            & input {
                                width: 100%;
                          } 
                        }
                    }
                }
            }
        }
    }
    .content {
        background-color: #F5F5F7;
        .inner {
            .inner-heading {
                position: relative;
                padding-bottom: 19px;
                p {
                    font-weight: 700;
                    font-family: "Libre Baskerville", sans-serif;
                    font-size: 24px;
                    line-height: 30px;
                    color: $main-blue-dark;
                }
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 130%;
                    height: 1px;
                    background-color: #E8E8E8;
                }
            }
            .form-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 25px 0;
                    border-bottom: 1px solid #EFEFEF;
                    p.title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        opacity: 0.75;
                    }
                    .inputs {
                        width: 693px;
                        p.account-data,
                        a.account-email {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 18px;
                        }
                        .input--group {
                            label {
                                font-size: 14px;
                                margin-bottom: 0;
                                height: 22px;
                            }
                            input {
                                height: 54px;
                                width: 100%;
                                padding: 0 20px;
                                &#alternative,
                                &#primary {
                                    letter-spacing: 3px;
                                }
                            }
                        }
                    }
                    &.two-block {
                        padding: 16px 0 21px;
                        .inputs {
                            display: flex;
                            justify-content: space-between;
                            flex-flow: wrap;
                            .input--group {
                                width: 331px;
                            }
                            span.message {
                                font-weight: 400;
                                margin-top: 8px;
                                margin-bottom: -10px;
                                font-style: italic;
                                font-size: 10px;
                                line-height: 22px;
                                letter-spacing: -0.02em;
                                color: #667080;
                            }
                        }
                    }
                    &.password {
                        .inputs {
                            display: flex;
                            align-items: center;
                            .input--group {
                                input {
                                    width: 293px;
                                }
                            }
                            button.change-pass-btn {
                                margin-left: 16px;
                                width: 100px;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
        }
        .btns-block {
            margin: 30px 0 45px;
            display: flex;
            justify-content: flex-end;
            a.cancel {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-decoration-line: underline;
                color: #002E6F;
                margin-right: 13px;
            }
            button {
                width: 90px;
                height: 42px;
            }
        }
    }
    &.care-giver {
        .content {
            .btns-block {
                margin: 34px 0 23px 0;
            }
        }
    }
}

