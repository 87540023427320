.portal-screen.resident-summary,
.portal-screen.caregiver-summary {
    position: relative;
    .content {
        h1 {
            margin: 10px 0 12px;
            font-family: "Libre Baskerville", sans-serif;
            font-weight: 700;
            font-size: 36px;
            // line-height: 120%;
            color: #0C2E6F;
        }
        p.secondary {
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #7B7B7B;
        }
        .add-new-block {
            display: flex;
            justify-content: space-between;
            margin-top: 56px;
            .item {
                background: #FFFFFF;
                box-shadow: 0 0 24px rgba(0, 46, 111, 0.06);
                border-radius: 12px;
                padding: 23px 10px 19px 15px;
                width: 477px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .desc {
                    p.title {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 24px;
                        letter-spacing: 0.18px;
                    }
                    p.second {
                        margin-top: 6px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }
                }
                a.portal-button {
                    height: 40px;
                    width: 175px;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                }
            }
        }
        .residents-block {
            margin: 56px 0 67px;
            h2 {
                font-family: "Libre Baskerville", sans-serif;
                font-weight: 700;
                font-size: 24px;
                line-height: 22px;
                color: #0C2E6F;
            }
            
        }
    }
    .modal-add-caregiver,
    .modal-add-resident {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 160, 223, 0.24);
        z-index: -999;
        opacity: 0;
        visibility: hidden;
        &.show {
            opacity: 1;
            visibility: visible;
            z-index: 999;
            .inner {
                top: 485px;
            }
        }
        &.modal-add-caregiver {
            &.show .inner {
                top: 420px;
            }
            .inner {
                top: 520px;
                left: 50%;
                width: 990px;
            }
        }
        .inner {
            position: absolute;
            top: 585px;
            left: 50%;
            padding: 43px 35px 28px;
            transform: translateX(-50%);
            background-color: #fff;
            border: 2px solid rgba(102, 112, 128, 0.07);
            box-shadow: 0 4px 26px rgba(0, 46, 111, 0.06);
            border-radius: 10px;
            transition: .3s all ease;
            width: 965px;
            p.heading {
                font-family: "Libre Baskerville", sans-serif;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: #0B508F;
            }
            form {
                margin-top: 25px;
                .form-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #EFEFEF;
                    height: 111px;
                    .left {
                        p.title {
                            opacity: 0.75;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 2px;
                            span {
                                color: red;
                            }
                        }
                        a.info {
                            font-family: "Open Sans", sans-serif;
                            font-size: 14px;
                            text-decoration: underline;
                            color: #259FDA;
                        }
                    }
                    .inputs {
                        display: flex;
                        justify-content: space-between;
                        width: 627px;
                        .input--group {
                            width: 298px;
                            label {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                            input {
                                height: 54px;
                            }
                        }
                        &.single {
                            .input--group {
                                width: 100%;
                            }
                        }
                    }
                }
                .buttons-row {
                    margin-top: 23px;
                    display: flex;
                    align-items: center;
                    a.cancel {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-decoration-line: underline;
                    }
                    button.portal-button {
                        margin-left: 15px;
                        height: 42px;
                        width: 90px;
                    }
                }
            }
        }
    }
    .modal-caregiver-success,
    .modal-caregiver-can-not-delete,
    .modal-resident-success {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 160, 223, 0.24);
        z-index: -999;
        opacity: 0;
        visibility: hidden;
        &.show {
            z-index: 999;
            opacity: 1;
            visibility: visible;
        }
        .inner {
            position: absolute;
            top: 376px;
            left: 50%;
            padding: 43px 35px 28px;
            transform: translateX(-50%);
            background-color: #fff;
            border: 2px solid rgba(102, 112, 128, 0.07);
            box-shadow: 0 4px 26px rgba(0, 46, 111, 0.06);
            border-radius: 10px;
            transition: .3s all ease;
            width: 990px;
            height: 720px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p.success-title {
                font-family: "Libre Baskerville", sans-serif;
                font-weight: 400;
                font-size: 50px;
                line-height: 62px;
            }
            p.title {
                display: inline-block;
                min-width: 100%;
                margin-top: 10px;
                font-weight: 400;
                font-size: 30px;
                line-height: 36px;
                span {
                    font-weight: 700;
                }
            }
            p.cause {
                font-style: italic;
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                width: 615px;
                margin-top: 10px;
                text-align: left;
            }
            .list {
                margin: 38px 0 -30px;
                width: 403px;
                height: 156px;
                overflow-y: auto;
                background: rgba(196, 196, 196, 0.15);
                border-radius: 31px;
                padding: 18px 30px;
                ul {
                    li {
                        p {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 150%;
                            letter-spacing: -0.02em;
                        }
                    }
                }
            }
            .buttons-block {
                display: flex;
                align-items: center;
                margin-top: 90px;
                a.not-right-now-button-caregiver-can-not-delete,
                a.not-right-now-button-caregiver-success,
                a.not-right-now-button {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration-line: underline;
                }
                a.portal-button {
                    width: 250px;
                    height: 50px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    margin-left: 27px;
                    justify-content: center;
                }
            }
        }
    }
}

.busy {
    opacity: 0.5;
    cursor: not-allowed;
}