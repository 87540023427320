.screen.please-call {
    padding-top: 216px;
    p.title {
        margin-top: 73px;
        font-weight: 600;
        line-height: 120%;
        width: 250px;
    }
    a.phone {
        font-weight: 800;
        margin-top: 40px;
    }
}
